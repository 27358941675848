import { sortObjectArray } from  'components/utils/helper.js'

class Position {
    constructor(year, position) {
        this.year = year;
        this.position = position;
    }
}

export default class SongTitleSearch {

    /**
     * @param {*} songTitle SongTitle object from server
     */
    constructor(songTitle) {
        this.titleId = parseInt(songTitle.titleId);
        this.titleName = songTitle.titleName;
        this.year = parseInt(songTitle.year);
        this.fragment = songTitle.fragment;

        if (songTitle.positions != null && songTitle.positions instanceof Array) {
            var positions = [];
            songTitle.positions.forEach(element => {
                if (element.year) {
                    positions.push(new Position(element.year, element.position));
                }
            });
            positions.sort(sortObjectArray('year', 'asc'));
            this.positions = positions;
        }
    }
}
