class OriginGroup {
    constructor(id, defaultGraphHeight, description) {
        this.id = id;
        this.defaultGraphHeight = defaultGraphHeight;
        this.description = description;
    }
}

export const originGroupsArray = [
    new OriginGroup(1, true, 'Nederland, Engeland en Verenigde Staten'),
    new OriginGroup(2, false, 'Overige landen')
];
