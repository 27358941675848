import { audioUrl } from "./helper.js";

export function playAudio(trackName, onAudioEnded) {
    var audioPlayer = document.getElementById("audio_player");
    if (audioPlayer == null) {
        return;
    }
    audioPlayer.src = audioUrl(trackName);
    audioPlayer.play();
    audioPlayer.addEventListener('ended', () => stopAudio(onAudioEnded));
}

export function stopAudio(onAudioEnded) {
    var audioPlayer = document.getElementById("audio_player");
    if (audioPlayer == null) {
        return;
    }
    audioPlayer.pause();
    audioPlayer.src = "";
    if (onAudioEnded !== null) {
        onAudioEnded()
    }
}
