export const baseUrl = "https://www.top2000info.nl/services.php";
//export const baseUrl = "http://localhost:8888/services.php";

export async function genericGet(queryString) {
    //console.log(baseUrl + queryString);
    let response = await fetch(baseUrl + queryString);

    if (response.status === 200) {
        let json = await response.json();
        return json;
    }
    
    throw new Error(response.status);
}

export async function genericPost(endpoint, body) {
    let response = await fetch(baseUrl + endpoint, {
        method: "POST",
        headers: {'Content-Type': 'application/json'}, 
        body: JSON.stringify(body)
    });

    if (response.status === 200) {
        return true;
    }

    throw new Error(response.status);
}
