export const navigationBarData = [
  {
    title: 'Home',
    iconClass: 'home',
    imageResource: 'navbar-home.png',
    url: '/',
  },
  {
    title: 'Lijsten',
    iconClass: 'lists',
    imageResource: 'navbar-list.png',
    url: '/lists',
  },
  {
    title: 'Statistieken',
    iconClass: 'stats',
    imageResource: 'navbar-stats.png',
    url: '/stats',
  },
  {
    title: 'Zoeken',
    iconClass: 'search',
    imageResource: 'navbar-search.png',
    url: '/search',
  },
  {
    title: 'Muziekquiz',
    iconClass: 'musicquiz',
    imageResource: 'navbar-music.png',
    url: '/musicquiz',
  },
  {
    title: 'Contact',
    iconClass: 'contact',
    imageResource: 'navbar-contact.png',
    url: '/contact',
  },
]
