class SearchTab {

    constructor(_uid, index, title, isSelected, component, searchType) {
        this._uid = _uid;
        this.index = index;
        this.title = title;
        this.isSelected = isSelected;
        this.component = component;
        this.searchType = searchType;
    }

}

export const searchTabData = [
    new SearchTab("LV4eJcCMt5", 0, "Artiest",  true , "searchTypeArtist", "artistId"),
    new SearchTab("P3zkA37DwL", 1, "Titel",    false, "searchTypeTitle", "title"),
    new SearchTab("6FUUqgCA7X", 2, "Herkomst", false, "searchTypeOrigin", "countryCode"),
    new SearchTab("DuvtK23LTm", 3, "Jaartal",  false, "searchTypeYear", "year"),
];

// OOK:
/*export const searchTabData = {
  ARTIST: {_uid: "LV4eJcCMt5", index: 0, title: "Artiest",  isSelected: true , component: "searchTypeArtist", searchType: "artistId"},
  TITLE:  {_uid: "P3zkA37DwL", index: 1, title: "Titel",    isSelected: false, component: "searchTypeTitle",  searchType: "title"},
  YEAR:   {_uid: "DuvtK23LTm", index: 2, title: "Jaartal",  isSelected: false, component: "searchTypeYear",   searchType: "year"},
  ORIGIN: {_uid: "DuvtK23LTm", index: 3, title: "Herkomst", isSelected: false, component: "searchTypeOrigin", searchType: "origin"},
}*/