/* String functions */
/*export const decodedUpperCasedWordsString = (str) => {
  return ucwords(decodedString(str));
};*/

export function classList(classes) {
  return Object
      .entries(classes)
      .filter(entry => entry[1])
      .map(entry => entry[0])
      .join(' ');
}

export function urlencode(str) {
  str = (str + '').toString();
  // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
  // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
  return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/-/g, '%2D').replace(/\./g, '%2E').replace(/%20/g, '+');
}

export const decodedString = (str) => {
  return decodeURIComponent(str).replace(/\+/g, ' ');
}

export const ucwords = (str) => {
  return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
};

/* Audio and Image functions */

export function audioUrl(track) {
    return "https://www.top2000info.nl/audio/" + track;
};

export function artistImageUrl(imageName, local = false) {
    if (local) {
        if (!imageName) {
            return String(require("assets/images/no_picture.png"));
        } else {
            return String(require("assets/backup/" + imageName))
        }
    } else {
        if (!imageName) {
            return "https://www.top2000info.nl/images/algemeen/no_picture.png";
        } else {
            return "https://www.top2000info.nl/images/artiesten/" + imageName;
        }
    }
};

/* Other helper functions */

export function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

export const sortObjectArray = (key, order = 'asc') => {
    return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
        }

        const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    };
}

export const printObject = (obj) => {
    console.log(JSON.parse(JSON.stringify(obj)));
}

export const debounce = (func, delay) => {
  let inDebounce
  return function() {
    const context = this
    const args = arguments
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}
/*
See also: https://codeburst.io/throttling-and-debouncing-in-javascript-b01cad5c8edf
Example
debounceBtn.addEventListener('click', debounce(function() {
  console.info('Hey! It is', new Date().toUTCString());
}, 3000));
*/
