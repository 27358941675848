import React from 'react'
import { Link } from 'react-router-dom'
import { artistImageUrl } from 'components/utils/helper'

// HomeSectionElement is an element defined in homeTopSectionData.js
const HomeTopSection = ({homeTopSectionElement}) => {
  
    const styling = {backgroundImage : "url('" + artistImageUrl(homeTopSectionElement.image) + "')"};

    return (
        <Link to={homeTopSectionElement.link}>
            <div className="relative w-48 md:w-64 h-56 bg-gray-800 border-2 border-black">
                <div className="absolute top-0 left-0 w-full h-full opacity-50 bg-no-repeat" style={styling} />
                <div className="absolute mt-2 mr-2 right-0">
                    <div className="p-2 bg-white text-gray-800 uppercase font-bold text-lg">{homeTopSectionElement.topLine}</div>
                </div>
                <div className="absolute w-full bottom-0 bg-gray-100">
                    <div className="m-2 text-gray-800 uppercase font-bold text-xs">{homeTopSectionElement.description}</div>
                </div>
            </div>
        </Link>
    )
}

export default HomeTopSection;
