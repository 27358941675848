import React, {useState} from 'react'
import { ImStatsDots } from 'react-icons/im';
import AudioButton from 'components/common/AudioButton'
import SongtitleGraphOverlay from 'components/common/SongtitleGraphOverlay.js'
import { artistImageUrl } from 'components/utils/helper'
import { FaChevronRight, FaChevronDown } from 'react-icons/fa'

const SearchResultSongTitle = ({songTitle, index, onStatsClicked}) => {
    return (
        <div className={`flex justify-between items-center h-16 ${index % 2 === 0 ? "bg-gray-800" : "bg-gray-700"}`}>
            <div className="w-1/4 ml-4">
                <span className="italic truncate text-gray-100">{songTitle.titleName} - {songTitle.year}</span>
            </div>
            <div className="hidden md:block flex-1">
                <div className="flex w-full justify-around items-center">
                    {songTitle.positions.sort((lhs, rhs) => lhs.year < rhs.year ? 1 : -1).map(function(position, i) {
                        const positionString = position.position === null ? "-" : position.position;
                        return (
                            <div className="flex flex-col" key={i}>
                                <span className="text-gray-100 text-sm text-center truncate">{position.year}</span>
                                <span className="text-gray-100 text-sm text-center font-bold">{positionString}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="hidden md:block w-12">
                <button className="text-gray-300 inline-flex items-center" onClick={() => onStatsClicked(songTitle.titleId)}>
                    <ImStatsDots className="w-6 h-6" />                    
                </button>
            </div>
            <div className="w-1/6 mr-2">
                <AudioButton fragment={songTitle.fragment} />
            </div>
        </div>
    )
}

const SearchResultArtist = ({searchResult, onStatsClicked}) => {
    const [open, setOpen] = useState(false);

    const handleArtistClick = () => {
        setOpen(!open);
    }

    return (
        <div className="w-full border border-white">
        <div className="flex p-2 justify-between items-center font-medium text-white cursor-pointer" onClick={() => {handleArtistClick()}}>
            <span>{open ? <FaChevronDown className="flex-shrink-0 w-4 h-4 m-2 text-white" /> : <FaChevronRight className="flex-shrink-0 w-4 h-4 m-2 text-white" />}</span>
            <span className="flex-1 ml-4">{searchResult.artistName}</span>
            <img className="flex-shrink-0 w-24 max-h-full ml-2 object-cover overflow-hidden" src={artistImageUrl(searchResult.photo)} alt={""} />
        </div>
        <div className={`w-full ${open ? "block" : "hidden"}`}>
        {searchResult.songTitles.map(function(songTitle, index) {
            return (
                <SearchResultSongTitle key={songTitle.titleId} songTitle={songTitle} index={index} onStatsClicked={onStatsClicked} />
            )
        })}
        </div>
        </div>
    )
}

const SearchResultContainer = ({searchResult}) => {
    
    // State and methods for presenting overlay
    const [titleId, setTitleId] = useState(null);
    const [isOverlayPresented, setIsOverlayPresented] = useState(false);

    const onStatsClicked = (titleId) => {
        setTitleId(titleId);
        setIsOverlayPresented(true);
    }

    function handleOverlayDismissed() {
        setIsOverlayPresented(false);
    }

    return (
        <div className="w-full py-8">
            <SongtitleGraphOverlay titleId={titleId} isOverlayPresented={isOverlayPresented} onDismiss={handleOverlayDismissed} />
            {searchResult.map(function(result, i) {
                return (
                    <SearchResultArtist key={i} searchResult={result} onStatsClicked={onStatsClicked} />
                )
            })}
        </div>
    )
}

export default SearchResultContainer;
