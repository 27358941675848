import React, {useState, useEffect} from 'react';

import PageControls, { PageChange } from './PageControls.js'
import { getList } from 'components/services/getList.js'
import SongTitle from 'components/models/SongTitle'
import Dropdown from 'components/common/dropdown/Dropdown.js';
import DropdownOption from 'components/common/dropdown/DropdownOption.js';
import ListComponent from 'components/common/ListComponent'
import getBroadcastingYears from 'components/services/getBroadcastingYears.js'
import { sortObjectArray } from 'components/utils/helper.js';

// create the jsonconfig.json file to prevent ugly imports using relative paths.

function LoadingLists() {
    return (
        <div>
        <p>Loading data... </p>
        </div>
    )
}

const ListControls = (
    {broadcastingYears, selectedBroadcastingYear, onBroadcastingYearChange,
    pagesizes, selectedPagesize, onPagesizeChange, onPageChange}) => {
    return (
        <div>
            <div className="flex justify-between mb-4">
                <Dropdown label='Uitzendjaar' options={broadcastingYears} selected={selectedBroadcastingYear} onSelectedChange={onBroadcastingYearChange} />
                <div className="hidden md:block">
                    <Dropdown label='Titels per pagina' options={pagesizes} selected={selectedPagesize} onSelectedChange={onPagesizeChange} />
                </div>
            </div>
            <PageControls onPageChange={onPageChange} />
        </div>
    )
}

export default function Lists() {
  
    const broadcastingYears = getBroadcastingYears().map(year => new DropdownOption(year, year)).sort(sortObjectArray('value', 'desc'));

    const pagesizes = [25, 50, 100].map(e => new DropdownOption(e, e));
    
    const [isLoading, setIsLoading] = useState(false);
    const [broadcastingYear, setBroadcastingYear] = useState(broadcastingYears[0].value);
    const [pagesize, setPagesize] = useState(pagesizes[0].value);
    const [page, setPage] = useState(1);
    const [songTitles, setSongTitles] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    async function fetchFullList() {
        setIsLoading(true);
        try {
            const result = await getList(broadcastingYear, page, pagesize);
            // Map data to a SongTitle object, containing properties 'position' and 'positionPreviousYear'.
            const newSongTitles = result.songTitles.map(songTitle => new SongTitle(songTitle, result.year));
            setSongTitles(newSongTitles);
            setIsLoading(false);
        } catch(error) {
            setIsLoading(false);
            setErrorMessage("Something went wrong... apologies");
        }
    }

    function handleBroadcastingYearChange(dropDownOption) {
        setBroadcastingYear(dropDownOption.value);
    }
    // Can also be defined as a const
    /*const handleBroadcastingYearChange = (dropDownOption) => {
        setBroadcastingYear(dropDownOption.value);
    }*/

    const handlePagesizeChange = (dropDownItem) => {
        setPagesize(dropDownItem.value);
    }

    function handlePageChange(pageChange) {
        const numPages = 2000 / pagesize;
        switch (pageChange) {
            case PageChange.FIRST:
                setPage(1);
                break;
            case PageChange.PREVIOUS:
                setPage(page - 1 > 0 ? page - 1 :  page);
                break;
            case PageChange.NEXT:
                setPage(page + 1 < pagesize ? page + 1 :  page);
                break;
            case PageChange.LAST:
                setPage(numPages);
                break;
            default:
                setPage(1);
                break;
        }
    }

    useEffect(() => {
        //setIsLoading(true);
        fetchFullList();
    }, [broadcastingYear, page, pagesize]); // Only rerun when broadcastingYear, page or pagesize has changed

    return (
        <div className="w-full">
        {errorMessage === null ? null : <p>{errorMessage}</p>}
        {songTitles === null ? <LoadingLists /> : (
            <div className="lg:mx-12">
                <ListControls
                    broadcastingYears={broadcastingYears}
                    selectedBroadcastingYear={broadcastingYear}
                    onBroadcastingYearChange={handleBroadcastingYearChange}
                    pagesizes={pagesizes}
                    selectedPagesize={pagesize}
                    onPagesizeChange={handlePagesizeChange}
                    onPageChange={handlePageChange} />
                <ListComponent isLoading={isLoading} songTitles={songTitles} />
                <PageControls onPageChange={handlePageChange} />
            </div>
        )}
        </div>
    );
}
