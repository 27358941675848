import SongTitle from 'components/models/SongTitle'

export default class SongTitleGone extends SongTitle {
  
    constructor(songTitle, broadcastYear = null) {
        super(songTitle, broadcastYear);
        this.yearLastEdition = songTitle.yearLastEdition;
    }

}
