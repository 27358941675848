import React, {useState} from 'react'
import { FaSearch } from 'react-icons/fa';

const SearchTypeTitle = ({handlePartialTitle}) => {
    const [searchText, setSearchText] = useState("");
    const [searchButtonEnabled, setSearchButtonEnabled] = useState(false);

    function handleInputChange(event) {
        setSearchButtonEnabled(event.target.value.length >= 3);
        setSearchText(event.target.value);
    }

    const handleClick = () => {
        handlePartialTitle(searchText);
    }

    return (
        <div className="flex flex-col w-full">
            <h2 className="text-white font-medium">Geef (een gedeelte van) een titel</h2>
            <div className="bg-white w-full md:w-1/2 border border-gray-800">
                <div className="flex items-center sm:flex-wrap lg:flex-nowrap">
                    <FaSearch className="w-8 h-8 m-4 text-gray-800" />
                    <div className="flex-1">
                        <input className="pl-4 border-0 w-full h-full outline-none" type="text" value={searchText} placeholder="Zoek titel" autoComplete="off" onChange={handleInputChange} />
                    </div>
                    <button className={`w-16 h-8 m-2 rounded-lg ${searchButtonEnabled ? "bg-blue-400 text-white cursor-pointer" : "bg-gray-400 text-gray-200 cursor-default"}`}
                        onClick={searchButtonEnabled ? handleClick : null}>Zoeken</button>
                </div>
            </div>
        </div>
    )
}

export default SearchTypeTitle;
