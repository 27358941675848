import React from 'react';

export const PageChange = {
    FIRST: "first",
    PREVIOUS: "previous",
    NEXT: "next",
    LAST: "last"
}

const PageControls = ({onPageChange}) => {

    const toFirstPage = () => {
        onPageChange(PageChange.FIRST);
    }

    const toPreviousPage = () => {
        onPageChange(PageChange.PREVIOUS);
    }

    const toNextPage = () => {
        onPageChange(PageChange.NEXT);
    }

    const toLastPage = () => {
        onPageChange(PageChange.LAST);
    }

    return (
        <div className="flex flex-grow flex-nowrap md:justify-end">
            <button className="flex-grow md:flex-grow-0 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2 md:px-4 inline-flex items-center rounded-l" onClick={() => toFirstPage()}>
                <img className="fill-current w-4 h-4 mr-2" src={require("assets/images/icons8-double-left-24.png")} />
                <span className="text-xs">Eerste</span>
            </button>
            <button className="flex-grow md:flex-grow-0 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2 md:px-4 inline-flex items-center" onClick={() => toPreviousPage()}>
                <img className="fill-current w-4 h-4 mr-2" src={require("assets/images/icons8-prev-24.png")} />
                <span className="text-xs">Vorige</span>
            </button>
            <button className="flex-grow md:flex-grow-0 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2 md:px-4 inline-flex items-center" onClick={() => toNextPage()}>
                <img className="fill-current w-4 h-4 mr-2" src={require("assets/images/icons8-right-button-24.png")} />
                <span className="text-xs">Volgende</span>
            </button>
            <button className="flex-grow md:flex-grow-0 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2 md:px-4 inline-flex items-center rounded-r" onClick={() => toLastPage()}>
                <img className="fill-current w-4 h-4 mr-2" src={require("assets/images/icons8-double-right-24.png")} />
                <span className="text-xs">Laatste</span>
            </button>
        </div>
    )
}

export default PageControls;
