export const Movements = [
    {
        id: 0,
        title: "Stijging met meer dan 500 plaatsen",
        lowerlimit: 500,
        upperlimit: null,
    },
    {
        id: 1,
        title: "Stijging vanaf 250 tot 500 plaatsen",
        lowerlimit: 250,
        upperlimit: 500,
    },
    {
        id: 2,
        title: "Daling vanaf 250 tot 500 plaatsen",
        lowerlimit: -500,
        upperlimit: -250,
    },
    {
        id: 3,
        title: "Daling met meer dan 500 plaatsen",
        lowerlimit: null,
        upperlimit: -500,
    }
];
