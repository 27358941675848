import { genericGet } from './baseUrl.js';

export async function getList(broadcastingYear, page, pagesize) {
    const queryString = "/list?year=" + broadcastingYear + "&page=" + page + "&pagesize=" + pagesize;
    return genericGet(queryString);
}

/*export async function getCompleteList(broadcastingYear) {
    const queryString = "?action=listFull&year=" + broadcastingYear;
    return genericGet(queryString);
}*/
