import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import {navigationBarData} from './navigationBarData'

const NavigationItem = ({navItem, onClick}) => {
    return (
        <Link to={navItem.url} onClick={onClick}>
            <div className="lg:px-4 py-1 px-0 block border-b-2 border-transparent hovereffect hover:border-indigo-400 hover:text-blue-600">
                <div className="flex flex-wrap items-center mx-4 min-w-full min-h-full">
                    <img src={String(require('assets/images/' + navItem.imageResource))} className="object-contain border-none w-6 h-6 mx-2" alt="" />
                    <span
                        className="flex-shrink flex-grow flex-auto leading-normal my-4 self-center relative text-left">
                        {navItem.title}
                    </span>
                </div>
            </div>
        </Link>
    )
}

export default function NavigationBar() {
    const [navbarOpen, setNavbarOpen] = useState(false);

    function toggleNavBarOpen() {
        setNavbarOpen(!navbarOpen);
    }

    return (
        <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-white mb-3">
            <div className="container px-4 mx-auto flex flex-wrap lg:flex-no-wrap items-center justify-between">
                <div className="w-full relative flex justify-between lg:justify-start">
                    <img src={String(require('assets/images/logo2.png'))} alt="" className="h-16" />
                    <button
                        className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                        type="button"
                        onClick={toggleNavBarOpen}>
                        <label htmlFor="menu-toggle" className="cursor-pointer lg:hidden block">
                            <svg className="fill-current text-gray-900" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <title>menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                            </svg>
                        </label>
                    </button>
                </div>
                <div className={"lg:flex flex-grow items-center" + (navbarOpen ? " flex" : " hidden")} id="example-navbar-danger">
                    <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                        {navigationBarData.map(function(navItem, i) {
                            return <NavigationItem navItem={navItem} onClick={toggleNavBarOpen} key={i} />
                        })}
                    </ul>
                </div>
            </div>
        </nav>
    );
  }