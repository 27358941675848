import React, { useState, useEffect } from 'react'
import Dropdown       from 'components/common/dropdown/Dropdown.js'
import DropdownOption from 'components/common/dropdown/DropdownOption.js'
import { statsNewTitlesPerYear } from 'components/services/statistics.js'
import SongTitle from 'components/models/SongTitle'
import { artistImageUrl, sortObjectArray } from 'components/utils/helper'
import getBroadcastingYears from 'components/services/getBroadcastingYears.js'

function LoadingData() {
    return (
        <div>
            <p>Loading data... </p>
        </div>
    )
}

const Controls = ({broadcastingYears, selectedBroadcastingYear, onBroadcastingYearChange}) => {
    return (
        <div className="flex justify-between my-4">
            <Dropdown label='Uitzendjaar' options={broadcastingYears} selected={selectedBroadcastingYear} onSelectedChange={onBroadcastingYearChange} />
        </div>
    )
}

const SubHeader = ({subHeaderText})  => {
    return (
        <div className="bg-gray-100 text-gray-800 rounded-xl p-4 mt-8 mb-2">
            <p>{subHeaderText.text1}<span className="font-bold">{subHeaderText.number}</span>{subHeaderText.text2}</p>
        </div>
    )
}

const ArtistPicture = ({image}) => {
    const styling = {backgroundImage : "url('" + artistImageUrl(image) + "')"};
    return (
        <div className="flex-shrink-0 ml-2 w-24 h-24 max-h-full bg-cover bg-center bg-no-repeat" style={styling} />
    );
}

const NewEntriesComponent = ({entries}) => {
    return (
        <div className="flex flex-wrap my-4">
        {entries.map((songTitle, index) => {
            return (
                <div className="flex h-24 w-full md:w-1/2 border border-black items-center p-2" key={index}>
                    <div className="flex-shrink-0 w-12 text-xl text-right font-bold text-gray-100 mr-2">{songTitle.position}</div>
                    <ArtistPicture image={songTitle.photo} />
                    <div className="flex-1 flex flex-col ml-2 overflow-hidden">
                        <div className="text-gray-300 text-lg italic truncate">{songTitle.titleName}</div>
                        <div className="text-gray-500 text-sm truncate">{songTitle.artistName}</div>
                    </div>
                </div>
            )
        })}
        </div>
    )
}

const FirstEntriesComponent = ({firstEntries}) => {
    const subHeaderText = {
        text1: "Er zijn ",
        number: firstEntries.length,
        text2: " titels in het geselecteerde uitzendjaar, die niet eerder in de lijst hebben gestaan."
    }
    return (
        <div className="w-full">
            <SubHeader subHeaderText={subHeaderText} />
            <NewEntriesComponent entries={firstEntries} />
        </div>
    )
}

const OtherEntriesComponent = ({otherEntries}) => {
    const subHeaderText = {
        text1: "De onderstaande ",
        number: otherEntries.length,
        text2: " titels zijn nieuw in de lijst, maar hebben al wel eerder in de Top2000 gestaan."
    }
    return (
        <div className="w-full">
            <SubHeader subHeaderText={subHeaderText} />
            <NewEntriesComponent entries={otherEntries} />
        </div>
    )
}

const StatsRecordsNew = ({}) => {
    const broadcastingYears = getBroadcastingYears().map(year => new DropdownOption(year, year)).sort(sortObjectArray('value', 'desc'));
    const [isLoading, setIsLoading] = useState(false);
    const [broadcastingYear, setBroadcastingYear] = useState(broadcastingYears[0].value);
    const [newEntries, setNewEntries] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    async function fetchNewTitles() {
        try {
            const result = await statsNewTitlesPerYear(broadcastingYear);
            // Data is returned as {firstEntries:[<SongTitle>],otherEntries:[<SongTitle>]}
            const f = result.firstEntries.map(e => new SongTitle(e, broadcastingYear)) ?? [];
            const o = result.otherEntries.map(e => new SongTitle(e, broadcastingYear)) ?? [];
            setNewEntries({"firstEntries": f, "otherEntries": o});
            setIsLoading(false);
        } catch(error) {
            setIsLoading(false);
            setErrorMessage("Something went wrong... apologies");
        }
    }

    const handleBroadcastingYearChange = (dropDownOption) => {
        setBroadcastingYear(dropDownOption.value);
    }

    useEffect(() => {
        setIsLoading(true);
        fetchNewTitles();
    }, [broadcastingYear]); // Only rerun when broadcastingYear has changed

    return (
        <div className="flex flex-col">
        {errorMessage === null ? null : <p>{errorMessage}</p>}
        <p className="font-medium text-lg md:text-2xl text-center text-gray-100">Nieuwe titels in de lijst</p>
        <p className="text-xs md:text-base text-white">Deze lijst toont de platen die nog niet eerder in de lijst hebben gestaan sinds het geselecteerde uitzendjaar.</p>
        {newEntries === null ? <LoadingData /> : (
            <div>
                <Controls 
                    broadcastingYears={broadcastingYears}
                    selectedBroadcastingYear={broadcastingYear}
                    onBroadcastingYearChange={handleBroadcastingYearChange} />
                <FirstEntriesComponent firstEntries={newEntries.firstEntries} />
                <OtherEntriesComponent otherEntries={newEntries.otherEntries} />
            </div>
            )}
        </div>
    );
}

export default StatsRecordsNew;
