import React, {useState, useEffect} from 'react'
import HomeTopSection from './homeTopSection.js'
import homeTopSectionData from './homeTopSectionData.js'
import HomeRandomList from './HomeRandomList.js'
import ImageSlider from 'components/common/ImageSlider.js'
import { getRandomTitles } from 'components/services/getRandomTitles'
import ScrollableTitleContainer from './scrollableTitleContainer'

const HomeDataLoading = ({}) => {
    return (
        <p>Loading...</p>
    )
}

function Home() {

    const [homeData, setHomeData] = useState(null);

    async function fetchRandomTitles() {
        try {
            const result = await getRandomTitles(homeTopSectionData.length);
            var homeData = homeTopSectionData;
            homeData.map(function(element, index) {
                element.image = result[index].photo; return element;
            });
            setHomeData(homeData);
        } catch(error) {
            setHomeData(homeTopSectionData);
        }
    }

    useEffect(() => {
        fetchRandomTitles();
    }, []);
    
    return (
        <div className="flex flex-col mx-2 w-full">
            <div className="hidden md:flex justify-between pb-16">
                {homeData == null ? <HomeDataLoading /> : homeData.map((data, index) => <HomeTopSection key={index} homeTopSectionElement={data} /> )}
            </div>
            <ScrollableTitleContainer />
            <HomeRandomList />
        </div>
    )
}

export default Home;
