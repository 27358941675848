import Artist from 'components/models/Artist'
import SongTitleSearch from './SongTitleSearch'

export default class SearchResult extends Artist {
    constructor(obj) {
        super(obj);
        if (obj.songTitles != null && obj.songTitles instanceof Array) {
            this.songTitles = [];
            obj.songTitles.forEach(element => {
                this.songTitles.push(new SongTitleSearch(element));
            });
        }
    }
}
