import React, { useState, useEffect } from 'react'
import StatisticsSelectItem from './StatisticsSelectItem'
import StatsComponents from './StatsComponents'
import { statisticTypesData } from './StatisticTypesData'
import { getRandomTitles } from '../../services/getRandomTitles'
import { FaArrowLeft } from 'react-icons/fa'

const StatisticsResetButton = ({onClick}) => {
    return (
        <div className="w-full">
            <button className="h-8 rounded-md bg-blue-400 text-white focus:bg-blue-600 inline-flex outline-none items-center px-2" onClick={() => onClick()}>
                <FaArrowLeft className="mr-2"/>
                <span className="flex-1">Terug naar statistiekenselectie</span>
            </button>
        </div>
    )
}

const StatisticsSelection = ({statisticTypes, onSelected}) => {
    return (
        <div className="container bg-gray-600 rounded-lg">
            <div className="flex flex-wrap">
                {statisticTypes.map(function(statisticType, index) {
                    return (
                        <div className="w-full lg:w-1/2 p-2" key={index}>
                            <StatisticsSelectItem statisticType={statisticType} onSelected={onSelected} />
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

function StatisticsMain() {
    const [statisticTypes, setStatisticTypes] = useState([]);

    function handleSelectedStatisticType(selectedIndex) {
        const updatedStatisticTypes = statisticTypes.map(function(item, index) {
            item.isSelected = index === selectedIndex;
            return item;
        })
        setStatisticTypes(updatedStatisticTypes);
    }

    const resetSelectedStatisticsType = () => {
        const updatedStatisticTypes = statisticTypes.map(function(item, index) {
            item.isSelected = false;
            return item;
        })
        setStatisticTypes(updatedStatisticTypes);
    }

    async function fetchRandomTitles() {
        try {
            const result = await getRandomTitles(statisticTypesData.length);
            // Add the image and artistName to each item of statisticTypes
            
            const updatedStatisticTypes = statisticTypesData.map(function(item, index) {
                item.artistName = result[index].artistName;
                item.image = result[index].photo;
                return item;
            })
            setStatisticTypes(updatedStatisticTypes);
        } catch(error) {
            console.log("ERROR fetchRandomTitles: " + error);
        }
    }

    useEffect(() => {
        fetchRandomTitles();
    }, []);

    function hasSelectedStatisticsType() {
        return statisticTypes.find(element => element.isSelected === true) !== undefined;
    }

    return (
        <div className="flex flex-col w-full mx-4 lg:mx-12">
            {hasSelectedStatisticsType() === true ? <StatisticsResetButton onClick={resetSelectedStatisticsType} /> : null}
            
            {statisticTypes.filter(item => item.isSelected).map(function(statisticType) {
                return StatsComponents(statisticType, );
            })}
            
            {hasSelectedStatisticsType() === true ? null : <StatisticsSelection statisticTypes={statisticTypes} onSelected={handleSelectedStatisticType} />}
        </div>
    );
}

export default StatisticsMain;
