import React from 'react'
import { playAudio, stopAudio } from 'components/utils/audio'

const AudioButton = ({fragment}) => {

    function handleAudio() {
        playAudio(fragment, null);
    }

    const bgColor = fragment === null ? "bg-gray-400" : "bg-blue-400";
    const textColor = fragment === null ? "text-gray-200" : "text-white";
    const focusColor = fragment === null ? "" : " focus:bg-blue-600";
    const stylingClasses = "w-full h-8 my-4 rounded-lg " + bgColor + " " + textColor + " " + focusColor;
    return (
        <button className={stylingClasses} disabled={fragment == null} onClick={() => handleAudio()}>Play</button>
    )
}

export default AudioButton;
