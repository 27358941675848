import React from "react"

const LoadingIndicator = ({isLoading}) => {
    const overlayClasses = "absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 " + (isLoading ? "" : "hidden");

    return (
        <div className={overlayClasses}>
            <div className="relative m-auto top-20 rounded-full h-32 w-32 border-b-4 border-red-300 rounded animate-spin" />
        </div>
    )
}

export default LoadingIndicator;
