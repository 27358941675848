import React from 'react'
import NavigationBar from './components/NavigationBar/navigationBar.js'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home           from 'components/pages/home/home.js'
import Lists          from 'components/pages/lists/Lists.js'
import StatisticsMain from 'components/pages/statistics/StatisticsMain'
import Search         from 'components/pages/search/Search'
import Musicquiz      from 'components/pages/quiz/Musicquiz'
import ContactForm    from 'components/pages/contact/ContactForm'
import Footer         from 'components/footer/Footer'
import RandomTitle    from 'components/common/RandomTitle'
import ForgottenTitle from 'components/common/ForgottenTitle'
import AudioPlayer    from 'components/common/AudioPlayer'

function App() {
    return (
        <div id="app" className="bg-gray-900">
            <BrowserRouter>
                <NavigationBar />
                <AudioPlayer />
                <div className="flex mt-8 mx-1 md:mx-2">
                    <div className="flex flex-1 justify-around w-full">
                        <Switch>
                            <Route path='/' exact component={Home} />
                            <Route path='/lists' exact component={Lists} />
                            <Route path='/stats' exact component={StatisticsMain} />
                            <Route path='/search' exact component={Search} />
                            <Route path='/musicquiz' exact component={Musicquiz} />
                            <Route path='/contact' exact component={ContactForm} />
                        </Switch>
                    </div>
                    <div className="hidden lg:block lg:w-1/6">
                        <RandomTitle />
                        <ForgottenTitle />
                    </div>
                </div>
                <Footer />
            </BrowserRouter>
        </div>
    );
}

export default App;
