import React, {useState, useEffect} from 'react'
import useInterval from 'components/utils/useInterval.js'
import { getRandomTitles } from 'components/services/getRandomTitles'
import SongTitle from 'components/models/SongTitle'
import { playAudio, stopAudio } from 'components/utils/audio'
import { artistImageUrl } from 'components/utils/helper'

const Error = ({message}) => {
    return (
        <p className="font-bold text-red-800">{message}</p>
    )
}

const LoadingRandomTitle = ({}) => {
    return (
        <div className="flex justify-center">
            <p className="font-bold text-lg">Loading...</p>
        </div>
    );
}

const AudioButton = ({fragment}) => {

    function handleAudio() {
        playAudio(fragment, null);
    }

    const bgColor = fragment === null ? "bg-gray-400" : "bg-blue-400";
    const textColor = fragment === null ? "text-gray-200" : "text-white";
    const focusColor = fragment === null ? "" : " focus:bg-blue-600 outline-none";
    const stylingClasses = "w-full h-8 my-4 rounded-lg " + bgColor + " " + textColor + " " + focusColor;
    return (
        <button className={stylingClasses} onClick={() => handleAudio()}>Play</button>
    )
}

const PositionsColumn = ({positions, marginClass}) => {
    const mainStyling = "flex flex-col w-full " + marginClass;
    return (
        <div className={mainStyling}>
        {positions.map(p => {
            return (
            <div className="flex justify-between text-xs" key={p.year}>
                <span>{p.year + ":"}</span>
                <span>{p.position == null ? " -- " : p.position}</span>
            </div>
            );
        })}
        </div>
    )
}

const RandomTitleComponent = ({songTitle}) => {
    const styling = {backgroundImage : "url('" + artistImageUrl(songTitle.photo) + "')"};
    /*var imageName = "assets/images/no_picture.png";
    if (songTitle.photo !== null || songTitle.photo !== undefined) {
        imageName = "assets/backup/" + songTitle.photo;
    }*/

    const numPositions = songTitle.positions.length;
    const numPositionsCol1 = Math.ceil(numPositions / 2);
    const positionsCol1 = songTitle.positions.reverse().slice(0, numPositionsCol1);
    const positionsCol2 = songTitle.positions.slice(numPositionsCol1);

    return (
        <div className="flex flex-col justify-center bg-gray-200 rounded-lg p-2">
            <div className="bg-white font-bold text-sm text-gray-800 p-2">Een greep uit de lijst</div>
            <img src={artistImageUrl(songTitle.photo)} className="w-full h-48 object-contain my-2" />
            <p className="font-bold text-sm italic">{songTitle.titleName + " (" + songTitle.year + ")"}</p>
            <p className="font-bold text-sm">{songTitle.artistName}</p>
            <AudioButton fragment={songTitle.fragment} />
            <div className="flex w-full justify-around mt-4">
                <PositionsColumn positions={positionsCol1} marginClass="mr-4" />        
                <PositionsColumn positions={positionsCol2} marginClass="ml-4" />
            </div>
        </div>
    );
}

const RandomTitle = ({}) => {

    const [randomSongTitle, setRandomSongTitle] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    async function fetchRandomTitle() {
        try {
            const result = await getRandomTitles(1);
            setRandomSongTitle(new SongTitle(result[0]));
            setErrorMessage(null);
        } catch(error) {
            setErrorMessage("Something went wrong... apologies");
        }
    }

    useEffect(() => {
        fetchRandomTitle();
    }, []);

    useInterval(() => {
        fetchRandomTitle()
    }, 30 * 1000);

    return (
        <div>
            {errorMessage != null ? <Error message={errorMessage} /> : false }
            {randomSongTitle == null ? <LoadingRandomTitle /> : <RandomTitleComponent songTitle={randomSongTitle} /> }
        </div>
    );
}

export default RandomTitle;
