export default class Artist {

  /**
   * @param {*} artist Artist object from server
   */
  constructor(artist) {
    this.artistId = parseInt(artist.artistId);
    this.artistName = artist.artistName;
    this.countryCode = artist.countryCode;
    this.country = artist.country;
    this.photo = artist.photo;
  }
  
}
