import React, { useState, useEffect, useRef } from 'react';
import { artistImageUrl } from 'components/utils/helper.js'
import { searchArtist } from 'components/services/search.js'
import Artist from 'components/models/Artist'
import { FaSearch } from 'react-icons/fa';

const DropDownItem = ({artist, handleSelectedArist}) => {
    var styling = {backgroundImage : "url('" + artistImageUrl(artist.photo) + "')"};
    return (
        <div className="flex max-w-xs md:max-w-sm border border-gray-400 cursor-pointer" onClick={() => handleSelectedArist(artist.artistId)}>
            <div className="w-16 h-16 max-h-full bg-cover bg-center bg-no-repeat" style={styling} />
            <div className="flex px-4 justify-between items-center">
                <div className="flex-col">
                <p className="text-gray-800 text-sm font-bold truncate">{artist.artistName}</p>
                </div>
            </div>
        </div>
    )
}

const SearchTypeArtist = ({handleSelectedArist}) => {

    const [searchText, setSearchText] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [open, setOpen] = useState(false); // Indicates whether the drop down content is visible or not
    const [errorMessage, setErrorMessage] = useState("");

    // set ref variable
    const ref = useRef()

    // close dropdown if clicked anywhere outside of dropdown
    // on initial render, add click event listener
    useEffect(() => {
        const onBodyClick = event => {
        // check if element that was clicked is inside of ref'd component
        // if so no action is required from this event listener so exit
        if (ref.current.contains(event.target)) {
            return
        }
        // else close the dropdown
            setOpen(false)
        }

        // add event listener
        document.body.addEventListener("click", onBodyClick)

        // CLEANUP
        // remove event listener
        return () => {
            document.body.removeEventListener("click", onBodyClick)
        }
    }, [])

    useEffect(() => {

        var timer = null;

        async function performSearch() {
            try {
                if (searchText.length < 2) {
                    setSearchResults([]);
                    return;
                }
                //console.log("performSearch for " + searchText);
                var result = await searchArtist(searchText);
                //console.log(result);
                setOpen(result.length > 0);
                setSearchResults(result.map(element => new Artist(element)));
            } catch(error) {
                setErrorMessage("Something went wrong... apologies");
            }
        }

        const setTimerForSearch = () => {
            if (timer !== null) {
                //console.log("clear the timer");
                clearTimeout(timer);
            }
            //console.log("searchText is now " + searchText);
            timer = setTimeout(performSearch, 500);
        }
        setTimerForSearch();
    }, [searchText]); // Only rerun if searchText has changed

    function handleInputChange(event) {
        setSearchText(event.target.value);
    }

    function handleSelection(artistId) {
        setOpen(false);
        handleSelectedArist(artistId);
    }

    return (
        <div className="flex flex-col w-full">
            <h2 className="text-white font-medium">Zoeken op artiest</h2>
            <div ref={ref} className="relative bg-white lg:w-1/2 border border-gray-800">
                <div className="flex">
                    <FaSearch className="w-8 h-8 m-4 text-gray-800" />
                    <div className="flex-1">
                        <input className="pl-4 border-0 w-full h-full outline-none" type="text" value={searchText} placeholder="Zoek artiest" autoComplete="off" onChange={handleInputChange}/>
                    </div>
                </div>
                    
                <div className={`absolute h-48 overflow-auto z-10 ${open ? "visible" : "invisible"}`}>
                    <div className="bg-white shadow-xs w-80" onClick={() => setOpen(!open)}>
                        {searchResults.map(function(artist, index) {
                        return <DropDownItem key={index} artist={artist} handleSelectedArist={handleSelection} />
                        })}
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default SearchTypeArtist;
