import React, {useRef, useState} from 'react'
import { FaUserCircle, FaRegClosedCaptioning, FaRegCheckCircle, FaRegEdit } from 'react-icons/fa'
import ErrorComponent from '../../common/ErrorComponent'
import LoadingIndicator from 'components/common/LoadingIndicator.js'
import { postContactMessage } from 'components/services/contactservice'

const MessageSuccessAlert = ({isPresenting, handleAlertButton}) => {
    const overlayClasses = "absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 " + (isPresenting ? "" : "hidden");

    return (
        <div className={overlayClasses}>
            <div className="absolute top-1/2 left-1/2 transform translate-x-1/2 translate-y-1/2 w-1/2 flex flex-col bg-white rounded-lg items-center py-4">
                <FaRegCheckCircle className="w-16 h-16 text-green-300" />
                <p className="text-2xl font-medium text-gray-800 py-8">Bericht succesvol verstuurd</p>
                <button className="w-24 h-12 rounded-lg text-white bg-blue-400 focus:bg-blue-600" onClick={() => handleAlertButton()}>Ok</button>
            </div>
        </div>
    )
}

const ContactForm = () => {

    const nameRef = useRef("");
    const emailRef = useRef("");
    const subjectRef = useRef("");
    const messageRef = useRef("");
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isPresentingSuccessAlert, setIsPresentingSuccessAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    function handleInputChange(event) {
        setIsButtonEnabled(nameRef.current.value !== "" && emailRef.current.value !== "" && subjectRef.current.value !== "" && messageRef.current.value !== "");
    }

    async function handleSubmit() {
        const mailData = {
            name: nameRef.current.value,
            email: emailRef.current.value,
            subject: subjectRef.current.value,
            message: messageRef.current.value
        }
        setIsSending(true);
        try {
            postContactMessage(mailData);
            setIsSending(false);
            setErrorMessage(null);
            setIsPresentingSuccessAlert(true);
        } catch(error) {
            setIsSending(false);
            setErrorMessage(error);
        }
    }

    function handleAlertButton() {
        nameRef.current.value = "";
        emailRef.current.value = "";
        subjectRef.current.value = "";
        messageRef.current.value = "";
        setIsButtonEnabled(false);
        setIsPresentingSuccessAlert(false);
    }

    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full md:w-1/2">
                <p className="font-medium text-lg md:text-2xl text-center text-gray-100">Contact</p>
                <p className="my-4 text-xs md:text-base text-white">Heeft u een vraag over deze site, ziet u een fout in de gegevens of wilt u iets anders kenbaar maken, vul dan het onderstaande formulier in.</p>
            </div>
            <div className="w-full md:w-1/2 p-8 flex flex-col border rounded-md border-gray-300">
                <div className="mb-4">
                    <label htmlFor="name" className="text-medium text-white">Naam</label>
                    <div className="flex border rounded-md border-gray-300 bg-white">
                        <FaUserCircle className="w-8 h-8 m-2 text-gray-800" />
                        <div className="flex-1">
                            <input ref={nameRef} className="pl-4 border-0 w-full h-full outline-none bg-red" name="name" type="text" placeholder="Uw naam" autoComplete="off" onChange={handleInputChange} />
                        </div>
                    </div>
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="text-medium text-white">E-mail</label>
                    <div className="flex border rounded-md border-gray-300 bg-white">
                        <FaRegClosedCaptioning className="w-8 h-8 m-2 text-gray-800" />
                        <div className="flex-1">
                            <input ref={emailRef} className="pl-4 border-0 w-full h-full outline-none" name="email" type="text" placeholder="Uw e-mail" autoComplete="off" onChange={handleInputChange} />
                        </div>
                    </div>
                </div>
                <div className="mb-4">
                    <label htmlFor="subject" className="text-medium text-white">Onderwerp</label>
                    <div className="flex border rounded-md border-gray-300 bg-white">
                        <FaRegEdit className="w-8 h-8 m-2 text-gray-800" />
                        <div className="flex-1">
                            <input ref={subjectRef} className="pl-4 border-0 w-full h-full outline-none" name="subject" type="text" placeholder="Onderwerp" autoComplete="off" onChange={handleInputChange} />
                        </div>
                    </div>
                </div>
                <div className="mb-4">
                    <label htmlFor="message" className="text-medium text-white">Bericht</label>
                    <textarea ref={messageRef} className="w-full h-24 px-3 py-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" name="message" placeholder="Uw bericht..." autoComplete="off" onChange={handleInputChange} />
                </div>
                <button className={`w-full h-12 my-4 rounded-lg text-white ${isButtonEnabled ? "bg-blue-400 focus:bg-blue-600" : "bg-gray-400 focus:bg-gray-600"}`} disabled={!isButtonEnabled} onClick={() => handleSubmit()}>Versturen</button>
            </div>
            <LoadingIndicator isLoading={isSending} />
            <MessageSuccessAlert isPresenting={isPresentingSuccessAlert} handleAlertButton={handleAlertButton} />
            {errorMessage === null ? null : <ErrorComponent errorText={errorMessage} /> }
        </div>
    )
}

export default ContactForm;
