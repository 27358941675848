import React from 'react'
import { artistImageUrl } from 'components/utils/helper'

const StatisticsSelectItem = ({statisticType, onSelected}) => {

    const handleSelectedStatisticType = (() => {
        if (!statisticType.isSelected) {
            onSelected(statisticType.index)
        }
    })
    
    const imageStyling = {backgroundImage : "url('" + artistImageUrl(statisticType.image) + "')"};
    const cursorStyling = statisticType.isSelected ? "" : "cursor-pointer"
    const bgStyling = statisticType.isSelected ? "bg-gray-600" : "bg-white"

    return (
        <div className={"relative w-full h-56 bg-gray-800 border-2 border-black " + cursorStyling} onClick={handleSelectedStatisticType}>
            <div className="absolute top-0 left-0 w-full h-full bg-no-repeat" style={imageStyling} />
            <div className={"absolute mt-2 mr-2 right-0 " + bgStyling}>
                <div className="p-2 text-gray-800 uppercase font-bold text-lg text-right">{statisticType.title}</div>
            </div>
            <div className={"absolute w-full bottom-0 " + bgStyling}>
                <div className="m-2 text-gray-800 uppercase font-bold text-xs">{statisticType.subtitle}</div>
            </div>
        </div>
    )
}

export default StatisticsSelectItem;
