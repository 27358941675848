import { genericGet } from './baseUrl.js'
import { urlencode } from '../utils/helper.js'

export const SearchType = {
    ARTIST: "artist",
    TITLE: "title",
    COUNTRY: "countryCode"
};

export async function search(searchType, arg) {
    const encodedArg = urlencode(arg);
    const queryString = "/search?" + searchType + "=" + encodedArg;
    return genericGet(queryString);
}

export async function searchArtist(partialName) {
    return search(SearchType.ARTIST, partialName);
}

export async function searchOrigin(countryCode) {
    return search(SearchType.COUNTRY, countryCode)
}
