import React, { useState, useEffect } from "react";
import ErrorComponent    from 'components/common/ErrorComponent'
import ListComponent     from 'components/common/ListComponent'
import SongTitle         from 'components/models/SongTitle'
import { getRandomList } from 'components/services/getRandomList'

const HomeRandomList = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [randomYear, setRandomYear] = useState(null);
    const [songTitles, setSongTitles] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null); 

    async function fetchRandomList() {
        setIsLoading(true);
        try {
            const result = await getRandomList();
            // Map data to a SongTitle object, containing properties 'position' and 'positionPreviousYear'.
            const newSongTitles = result.songTitles.map(songTitle => new SongTitle(songTitle, result.year));
            setRandomYear(result.year);
            setSongTitles(newSongTitles);
        } catch(error) {
            setErrorMessage("Something went wrong... apologies");
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchRandomList();
    }, []);

    return(
        <div className="flex w-full">
            {errorMessage == null ? null : (
                <ErrorComponent errorText={errorMessage} />
            )}
            {randomYear == null ? null : (
                <div className="w-full">
                    <div className="p-2 bg-white text-gray-800 uppercase font-bold text-lg">Zo zag de lijst er uit in {randomYear}</div>
                    <ListComponent isLoading={isLoading} songTitles={songTitles} />
                </div>
            )}
        </div>
    )
};

export default HomeRandomList;
