import React, { useState, useEffect, useRef } from "react"

export const DropdownWidth = {
  SMALL: 0,
  MEDIUM: 1,
  LARGE: 2,
  properties: {
    0: {className: "w-24"},
    1: {className: "w-48"},
    2: {className: "w-72"}
  }
}

// props:
// - label: the label to display before the drop down control
// - options: the options(Array of type DropdownOption)
// - dropdownWidth: optional width for the drop down control
// - selected: optional value of an available DropdownOption
// - onSelectedChange: callback when a value is selected
const Dropdown = ({ label, options, dropdownWidth, selected, onSelectedChange }) => {
  const selectedOption = selected === null ? null : options.find(e => e.value === selected);
  // state to manage toggle visibility
  const [open, setOpen] = useState(false)
  // set ref variable
  const ref = useRef()

  // close dropdown if clicked anywhere outside of dropdown
  // on initial render, add click event listener
  useEffect(() => {
    const onBodyClick = event => {
      // check if element that was clicked is inside of ref'd component
      // if so no action is required from this event listener so exit
      if (ref.current.contains(event.target)) {
        return
      }
      // else close the dropdown
      setOpen(false)
    }

    // add event listener
    document.body.addEventListener("click", onBodyClick)

    // CLEANUP
    // remove event listener
    return () => {
      document.body.removeEventListener("click", onBodyClick)
    }
  }, [])

  // map options from props
  const renderedOptions = options.map(option => {
    return (
      <div
        key={option.value}
        className="block px-4 py-1 text-sm text-right leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
        // on click change selection to current option
        onClick={() => onSelectedChange(option)}
      >
        {option.label}
      </div>
    )
  })

  //<div className={`flex flex-row items-center ${dropdownWidth !== null ? DropdownWidth.properties[dropdownWidth].className : ""}`}>
  return (
    <div ref={ref} className="relative inline-block text-left w-72">
      <div className="flex flex-row items-center">
        <span className="text-white font-medium mr-2">{label}</span>
        <div className="rounded-md shadow-sm">
          <button
            type="button"
            onClick={() => setOpen(!open)}
            className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
            aria-haspopup="true" aria-expanded="true">
            {selectedOption === null ? "" : selectedOption.label}
            <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      </div>

      <div className={`origin-top-right absolute right-0 mt-0 h-24 overflow-auto rounded-md shadow-lg z-10 ${open ? "visible" : "invisible"}`}>
        <div className="rounded-md bg-white shadow-xs">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu" onClick={() => setOpen(!open)}>
            {renderedOptions}
          </div>
        </div>
      </div>
    
    </div>
    
  )
}

export default Dropdown;
