import { genericGet } from './baseUrl.js'

export async function statsTitlesPerDecade(broadcastYear, decadeId) {
    const queryString = "/stats/titlesPerDecade?year=" + broadcastYear + "&decade=" + decadeId;
    return genericGet(queryString);
}

export async function statsNewTitlesPerYear(broadcastYear) {
    const queryString = "/stats/titlesNew?year=" + broadcastYear;
    return genericGet(queryString);
}

export async function statsTitlesGone(char, page) {
    const queryString = "/stats/titlesGone?letter=" + char + "&page=" + page;
    return genericGet(queryString);
}

export async function statsOrigin(broadcastYear, originGroup) {
    const queryString = "/stats/titlesPerOrigin?year=" + broadcastYear + "&originGroup=" + originGroup;
    return genericGet(queryString);
}

export async function statsMovements(broadcastYear, lowerlimit, upperlimit) {
    var queryString = "/stats/titlesPositionDifference?year=" + broadcastYear;
    if (lowerlimit !== null) {
        queryString = queryString + "&lowerlimit=" + lowerlimit;
    }
    if (upperlimit !== null) {
        queryString = queryString + "&upperlimit=" + upperlimit;
    }
    return genericGet(queryString);
}
