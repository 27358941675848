export class Decade {
  
    constructor(decadeId, description) {
        this.decadeId = decadeId;
        this.description = description;
    }

}

export const decadesArray = [
    new Decade(1, 't/m 1959'),
    new Decade(2, '1960 - 1969'),
    new Decade(3, '1970 - 1979'),
    new Decade(4, '1980 - 1989'),
    new Decade(5, '1990 - 1999'),
    new Decade(6, '2000 - 2009'),
    new Decade(7, '2010 - 2019'),
    new Decade(8, 'vanaf 2020')
];
