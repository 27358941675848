import React, {useState, useEffect} from 'react'
import MusicquizSongTitle from './models/MusicquizSongtitle'
import SongTitle from 'components/models/SongTitle'
import AudioButton from 'components/common/AudioButton'
import ErrorComponent from 'components/common/ErrorComponent'
import { getMusicquiz } from 'components/services/quizservice'
import { stopAudio } from 'components/utils/audio'
import { artistImageUrl } from 'components/utils/helper'
import { FaRedo } from 'react-icons/fa'


const ListPicture = ({image}) => {
    const styling = {backgroundImage : "url('" + artistImageUrl(image) + "')"};
    return (
        <div className="w-16 h-16 md:w-32 md:h-24 max-h-full bg-cover bg-center bg-no-repeat" style={styling} />
    );
}

const MusicquizRowComponent = ({songTitle}) => {
    const [isRevealed, setIsRevealed] = useState(false);

    return (
        <div className="flex w-full h-16 md:h-24 items-center items-center bg-gray-800 rounded-md mb-2">
            <div className={`flex w-3/4 md:w-5/6 items-center mr-8 ${isRevealed ? " transition transition-opacity ease-out duration-750 opacity-1" : "opacity-0"}`}>
                <ListPicture image={songTitle.photo} />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <div className="pl-4 text-left text-gray-300 text-sm md:text-lg italic truncate">{songTitle.titleName}</div>
                    <div className="pl-4 text-left text-gray-500 text-sm md:text-lg truncate">{songTitle.artistName}</div>
                </div>
                <div className="hidden md:block w-1/12 flex flex-col">
                    <div className="text-center text-gray-300">{songTitle.topPosition}</div>
                    <div className="text-center text-gray-600 text-sm">Top positie</div>
                </div>
                <div className="hidden md:block w-1/12 flex flex-col">
                    <div className="text-center text-gray-300">{songTitle.numEditions}</div>
                    <div className="text-center text-gray-600 text-sm"># edities</div>
                </div>
            </div>
            <div className="flex w-1/4 md:w-1/6 items-center justify-between">
                <div className="w-1/2">
                    <AudioButton fragment={songTitle.fragment} />
                </div>
                <button className="w-1/2 h-8 my-4 rounded-lg mx-2 md:mx-4 text-white bg-red-400 focus:bg-red-600" onClick={() => setIsRevealed(true)}>??</button>
            </div>
        </div>
    )
}

const MusicquizContainer = ({songTitles}) => {
    return (
        <div className="flex flex-col w-full">
            {songTitles.map(function(songTitle, i) {
            return (
                <MusicquizRowComponent key={songTitle.titleId} songTitle={songTitle} /> 
            );
        })}
        </div>
    )
}

const Musicquiz = ({}) => {

    const [songTitles, setSongtitles] = useState(null);
    const [errorText, setErrorText] = useState(null);
  
    async function getSongtitlesForQuiz() {
        try {
            const result = await getMusicquiz();
            
            var resultArray = [];
            if (result instanceof Array) {
                if (result.length === 0) {
                    setErrorText("Er is een fout opgetreden bij het laden van de muziekquiz");
                } else {
                    resultArray = result.map(element => new SongTitle(element));
                }
            } else {
                setErrorText("Er is een fout opgetreden bij het laden van de muziekquiz");
            }
            setSongtitles(resultArray);
            setErrorText(null);
        } catch(error) {
            setErrorText(error.message);
        }
    }

    function refreshQuiz() {
        stopAudio(null);
        getSongtitlesForQuiz();
    }

    useEffect(() => {
        getSongtitlesForQuiz();
    }, []); // Only rerun when ??? has changed

    return (
        <div className="flex flex-col w-full lg:mx-12">
            <p className="font-medium text-lg md:text-2xl text-center text-gray-100">Muziekquiz</p>
            <p className="text-xs md:text-base text-white mb-8">Er zijn tien willekeurige fragmenten geselecteerd. Herkent u de fragmenten?</p>
            <div className="flex justify-end mb-4">
                <button className="w-32 h-8 rounded-lg bg-blue-400 focus:bg-blue-600 text-white inline-flex items-center px-2" onClick={() => refreshQuiz()}>
                    <FaRedo className="w-4 h-4 mr-2" />
                    <span className="flex-1 text-xs">Opnieuw</span>
                </button>
            </div>
            {errorText === null ? null : <ErrorComponent errorText={errorText} />}
            {songTitles === null ? null : <MusicquizContainer songTitles={songTitles} />}
        </div>
    )
}
  
export default Musicquiz;
