// https://www.storyblok.com/tp/react-dynamic-component-from-json

import React from 'react'
import StatsRecordsPerDecade from './StatsRecordsPerDecade'
import StatsRecordsNew from './StatsRecordsNew'
import StatsRecordsGone from  './StatsRecordsGone'
import StatsRecordsOrigin from './StatsRecordsOrigin'
import StatsRecordsMovement from  './StatsRecordsMovement'

export const StatsComponents = {
    statsRecordsPerDecade: StatsRecordsPerDecade,
    statsRecordsNew: StatsRecordsNew,
    statsRecordsGone: StatsRecordsGone,
    statsRecordsOrigin: StatsRecordsOrigin,
    statsRecordsMovement: StatsRecordsMovement
}

export default block => {
    // component does exist
    if (typeof StatsComponents[block.component] !== "undefined") {
        return React.createElement(StatsComponents[block.component], {
            key: block._uid,
            block: block
        });
    }
    // component doesn't exist yet
    return React.createElement(
        () => <div>The component {block.component} has not been created yet.</div>,
        { key: block._uid }
    );
}
