import {sortObjectArray} from 'components/utils/helper'

class Position {
    constructor(year, position) {
        this.year = year;
        this.position = position;
    }
}

export default class SongTitle {

    /**
     * @param {*} songTitle SongTitle object from server
     * @param {int, optional} broadcastYear Year of broadcast
     */
    constructor(songTitle, broadcastYear = null) {
        this.artistId = parseInt(songTitle.artistId);
        this.artistName = songTitle.artistName;
        this.titleId = parseInt(songTitle.titleId);
        this.titleName = songTitle.titleName;
        this.year = parseInt(songTitle.year);
        this.countryCode = songTitle.countryCode;
        this.country = songTitle.country;
        this.photo = songTitle.photo;
        this.fragment = songTitle.fragment;
        this.topPosition = songTitle.topPosition;
        this.numEditions = songTitle.numEditions;
        
        if (songTitle.positions !== null && songTitle.positions instanceof Array) {
            var positions = [];
            songTitle.positions.forEach(element => {
                if (element.year) {
                    positions.push(new Position(element.year, element.position));
                }
            });
            positions.sort(sortObjectArray('year', 'asc'));
            this.positions = positions;
        }
    
        if (broadcastYear !== null) {
            var positionCurrentYear = songTitle.positions.find(e => e.year === parseInt(broadcastYear));
            if (positionCurrentYear) {
                this.position = parseInt(positionCurrentYear.position);
            }
            var positionPreviousYear = songTitle.positions.find(e => e.year === parseInt(broadcastYear) - 1);
            if (positionPreviousYear) {
                this.positionPreviousYear = parseInt(positionPreviousYear.position);
            }
        }
    }

    getPositionsArray(positionsArray) {
        var result = [];
        if (positionsArray instanceof Array) {
            positionsArray.forEach(element => {
                if (element.year) {
                    result.push(new Position(element.year, element.position));
                }
            });
            positionsArray.sort(sortObjectArray('year', 'asc'));
        }
    }
    
}
