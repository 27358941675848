const homeTopSectionData = [
  {
    topLine: 'Lijsten',
    description: 'De lijsten van alle uitzendjaren',
    link: '/lists',
  },
  {
    topLine: 'Statistieken',
    description: 'Bekijk verschillende statistieken',
    link: '/stats',
  },
  {
    topLine: 'Zoeken',
    description: 'Doorzoek de lijsten op verschillende manieren',
    link: '/search',
  },
]

export default homeTopSectionData;
