import React, {useState, useEffect} from 'react'
import ErrorComponent from 'components/common/ErrorComponent'
import Dropdown, {DropdownWidth} from 'components/common/dropdown/Dropdown.js'
import DropdownOption from 'components/common/dropdown/DropdownOption.js'
import {getYearsOfTitles} from 'components/services/getYearsOfTitles'

const YearDropDown = ({years, selectedYear, onYearChange}) => {
    return (
        <div className="flex justify-between my-4">
           <Dropdown label='Jaar' options={years} dropdownWidth={DropdownWidth.LARGE} selected={selectedYear} onSelectedChange={onYearChange} />
        </div>
    )
}

const SearchTypeYear = ({handleSelectedYear}) => {

    const [selectedYear, setSelectedYear] = useState(null);
    const [years, setYears] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    function onYearChange(dropDownOption) {
        setSelectedYear(dropDownOption.value)
        handleSelectedYear(dropDownOption.value)
    }

    async function getYears() {
        try {
            const result = await getYearsOfTitles()
            const dropDownItems = result.map(year => new DropdownOption(year, year));
            setYears(dropDownItems)
        } catch(error) {
            setErrorMessage("Something went wrong... apologies");
        }
    }

    useEffect(() => {
        getYears()
    }, []); // Only rerun if ... has changed

  
    return (
        <div className="w-full">
            <h2 className="text-white font-medium">Selecteer een jaar van uitgave.</h2>
            {years.length === 0 ? null : <YearDropDown years={years} selectedYear={selectedYear} onYearChange={onYearChange} /> }
            {errorMessage === null ? null : <ErrorComponent errorText={errorMessage} />}
        </div>
    )
}

export default SearchTypeYear;
