const getBroadcastingYears = function(startYear = 1999) {
    const endYear = 2023;

    if (startYear < 1999 || startYear > endYear) {
        startYear = 1999
    }
    
    var years = [];
    for(var year = startYear; year <= endYear; year++) {
        years.push(year);
    }
    return years;
}

export default getBroadcastingYears;
